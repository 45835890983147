import { createStore } from 'vuex'
import treeSort from '@/utils/treeSort'
import { _system } from '@/api'

export default createStore({
  state: {
    loading: false,
    currentTab: 'home-page',
    language: 'zh-HK',
    siftLangs: [],
    langs: [
      {
        name: '繁體',
        lang: 'zh-HK',
      },
      {
        name: '简体',
        lang: 'zh-CN',
      },
      { name: 'EN', lang: 'en' },
    ],
    dictionary: {},
    authorization: localStorage.getItem('authorization'),
    personal: localStorage.getItem('personal'),
    timer: null,
  },
  mutations: {
    CHANGE_LOADING(state, payload) {
      state.loading = payload
    },
    SET_CURRENT_TAB: (state, tab) => {
      state.currentTab = tab
    },
    SET_LANGUAGE: (state, lang) => {
      localStorage.setItem('language', lang)
      state.language = lang
    },
    SET_SIFTLANGS: (state, lang) => {
      state.siftLangs = [...lang]
    },
    SET_DICTNARY: (state, data) => {
      localStorage.setItem('dictionary', JSON.stringify(data))
      state.dictionary = data
    },
    SET_AUTH: (state, data) => {
      localStorage.setItem('authorization', data)
      state.authorization = data
    },
    SET_PERSONAL: (state, data) => {
      localStorage.setItem('personal', data)
      state.personal = data
    },
  },
  actions: {
    async FETCH_DICTIONARY({ commit }, params) {
      _system.getAllDictionary().then(
        (res) => {
          let data = {}
          res.forEach((item) => {
            if (Object.prototype.hasOwnProperty.call(data, item.field)) {
              data[item.field].push(item)
            } else {
              data[item.field] = []
              data[item.field].push(item)
            }
          })
          commit('SET_DICTNARY', data)
        },
        (res) => {
          commit('SET_DICTNARY', [])
        }
      )
    },
  },
  modules: {},
})
