import { opt } from '../index'

const baseUrl = '/ex/user/v1/common'

export default {
  // 获取图片链接
  getFile: (data = {}) => opt({ url: `${baseUrl}/common/file`, data, method: 'get' }),
  // 手机区号
  getMobileCode: (data = {}) => opt({ url: `${baseUrl}/code/country`, data, method: 'get' }),
}
