import {opt} from '../index'

export default {
  // 文章类型
  docType: (data = {}) => opt({url: `/ex/infra/index/doc/type`, data, method: 'get'}),
  // 文章列表
  docList: (data = {}) => opt({url: `/ex/infra/index/AFP_SITE/market`, data, method: 'get'}),
  // 文章详情
  docDetail: (data = {}) => opt({url: `/ex/infra/index/doc/detail`, data, method: 'get'}),
}
